import { memo, useContext, useState } from 'react'
import { Accordion, AccordionSummary, Typography, AccordionDetails, ButtonBase } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { Palette, PaletteTheme } from '../../util/palette/Palette'
import Link from 'next/link'
import { makeStyles } from 'makeStyles'
import { renderLog } from '../../util/utilFunctions'
import { UserSettingsContext } from '../../contexts/UserSettingsContext'
import { comicSansMediumSubsetFont } from '../../util/fonts/baseNextFontLoader'
import { Settings } from '@mui/icons-material'

// SECTION: Styles
const useStyles = (palette: PaletteTheme) => {
	const { background4, link4, link2, text1, divider1, linkHoverTransition } = palette

	return makeStyles()({
		root: {
			display: 'flex',
			justifyContent: 'flex-start',
			maxWidth: '100%',
			padding: 0,
			backgroundColor: background4,
			marginTop: 0,
			marginBottom: '1rem',
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				marginBottom: '0.5rem'
			}
		},
		accordionContainer: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			background: background4,
			color: text1
		},
		summaryContainer: {
			minHeight: '1rem !important',
			borderBottom: `1px ${divider1} solid`,
			'& .MuiAccordionSummary-content, .Mui-expanded': {
				marginTop: '0.7rem !important', // !important, otherwise only works in dark mode for some reason
				marginBottom: '0.4rem !important'
			},
			'& .MuiAccordionSummary-expandIconWrapper': {
				margin: 0,
				color: text1
			}
		},
		summaryText: {
			margin: 0,
			fontWeight: 'bold'
		},
		detailsContainer: {
			display: 'flex',
			flexDirection: 'column',
			padding: '1rem'
		},
		headerText: {
			marginTop: '0.5rem',
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				fontSize: 15
			}
		},
		_centeredHeaderText: {
			textAlign: 'center'
		},
		list: {
			listStyleType: 'square',
			paddingLeft: '2.5rem',
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				paddingLeft: '1.3rem'
			},
			'& li': {
				margin: 0,
				marginTop: '0.5rem',
				minHeight: 0,
				height: '100%',
				['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
					fontSize: 14
				}
			}
		},
		featuresText: {
			margin: 0,
			marginTop: '0.1rem',
			minHeight: 0,
			height: '100%',
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				fontSize: 14
			}
		},
		link: {
			textDecoration: 'none',
			transition: linkHoverTransition,
			color: link2,
			'&:hover': {
				color: link4
			}
		},
		comicSansText: {
			fontFamily: comicSansMediumSubsetFont
		},
		settingsIcon: {
			fontSize: 16,
			marginBottom: -2
		},
		divider: {
			width: '100%',
			borderBottom: `solid 1px ${divider1}`,
			marginTop: '1.5rem',
			marginBottom: '0.3rem'
		}
	})
}

const NewsContainer = memo(() => {
	// SECTION: Hooks
	const { userSettingsObj } = useContext(UserSettingsContext)

	const [open, setOpen] = useState(false)

	const { classes, cx } = useStyles(Palette.getPalette())()

	// SECTION: Render UI
	return (
		<div className={classes.root}>
			{renderLog('NEWS CONTAINER')}
			<Accordion
				className={classes.accordionContainer}
				expanded={open}
				onChange={(event, expanded) => setOpen(expanded)}>
				<AccordionSummary
					className={classes.summaryContainer}
					tabIndex={-1}
					expandIcon={
						<ButtonBase
							focusRipple={true}
							disableTouchRipple={true}
							aria-label={`${open ? 'Collapse' : 'Expand'} Latest Features`}>
							<ExpandMore />
						</ButtonBase>
					}>
					<Typography className={classes.summaryText} variant='h5' component='h2' noWrap={true}>
						Latest Features: {'\u00A0'}Fonts and Themes
					</Typography>
				</AccordionSummary>
				<AccordionDetails className={classes.detailsContainer}>
					<Typography className={cx(classes.headerText, classes._centeredHeaderText)} component='h3'>
						<strong>
							~ For Users Subscribed to{'\u00A0'}
							<Link href='/premium' className={classes.link}>
								Premium{'\u00A0'}
							</Link>
							~
						</strong>
					</Typography>
					<Typography className={classes.headerText} component='h4'>
						<strong>New Fonts:</strong> 22 additional fonts to type in, including everyone's favorite —{' '}
						<span className={classes.comicSansText}>Comic Sans!</span>
						{'\u00A0'}
						Non-English character support varies between fonts.
					</Typography>
					<Typography className={classes.headerText} component='h4'>
						<strong>New Themes:</strong> 15 additional theme presets to choose from. Finally the light/dark mode duopoly
						is a thing of the past!
					</Typography>
					<Typography className={classes.headerText} component='h4'>
						<strong>Customizable Themes:</strong> Those 15 new themes can be fully customized to your suit your distinct
						sense of style and taste.
					</Typography>
					<Typography className={cx(classes.headerText, classes._centeredHeaderText)} component='h4'>
						<strong>
							Find these new features in the Typing Console settings menu <Settings className={classes.settingsIcon} />.
						</strong>
					</Typography>
					<div className={classes.divider} />
					<Typography className={cx(classes.headerText, classes._centeredHeaderText)} component='h3'>
						<strong>~ For Everyone ~</strong>
					</Typography>
					<Typography className={classes.headerText} component='h4'>
						<strong>New Fonts (free ones):</strong> 2 new fonts to accommodate the dyslexic and visually impaired.
					</Typography>{' '}
					<Typography className={classes.headerText} component='h4'>
						<b>New Classic Books:</b> "Gulliver's Travels" • "Middlemarch" • "Mrs. Dalloway" • "Walden" • "The Wind in
						the Willows" • "The Wealth of Nations"
					</Typography>
					<div className={classes.divider} />
					<Typography className={classes.headerText} component='h4'>
						<strong>Found a bug?:</strong> Feel free to report it to&nbsp;
						<Link href='mailto:contact@typelit.io' className={classes.link}>
							contact@typelit.io
						</Link>
						{'\u00A0'}(Please don't report spelling or grammatical errors found in books).
					</Typography>
				</AccordionDetails>
			</Accordion>
		</div>
	)
})

export default NewsContainer
