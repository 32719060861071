import { Grid } from '@mui/material'
import { isMobile } from 'react-device-detect'
import LazyLoad from 'react-lazyload'
import BookCard from './BookSelectCards/BookCard'
import DisappearedLoading from '../../util/react-loadingg/DisappearedLoading'
import { Palette } from '../../util/palette/Palette'
import { BookDisplayTab } from './BooksDisplayTabs'
import { devLog, renderLog } from '../../util/utilFunctions'
import { UserAccountType } from '../../types'
import AddNewCustomBookCard from './BookSelectCards/AddNewCustomBookCard'
import QuickConvertTextCard from './BookSelectCards/QuickConvertTextCard'
import LoadingOverlay, {
	LoadingBackgroundType,
	LoadingIconType,
	LoadingOverlaySize
} from '../sharedComponents/LoadingOverlay'
import { SimpleBookDetails } from './BookSelectPage'
import { DisplayType } from '../../contexts/UtilContext'
import { useContext, useMemo } from 'react'
import { UserSettingsContext } from '../../contexts/UserSettingsContext'

// SECTION: Props
const addNewBookCard = {
	bookTitle: 'Add New Custom Book',
	displayTitle: 'Add New Custom Book',
	author: '',
	language: '',
	pageCount: 0,
	customBook: true
}

interface BooksDisplayProps {
	allActiveBookDetails: SimpleBookDetails[]
	activeTab: BookDisplayTab | null
	userAccountType: UserAccountType
	fetchingCustomBooks: boolean
	displayType: DisplayType
}

const BooksDisplay = ({
	allActiveBookDetails,
	activeTab,
	userAccountType,
	fetchingCustomBooks,
	displayType
}: BooksDisplayProps) => {
	// SECTION: Hooks
	const { userSettingsObj } = useContext(UserSettingsContext)
	const { text1 } = Palette.getPalette() // for applying inline styles

	// SECTION: UI Getters
	const getSectionSize = () => {
		switch (displayType) {
			case DisplayType.DESKTOP_DISPLAY:
				return 4
			case DisplayType.TABLET_DISPLAY:
				return 6
			case DisplayType.MOBILE_DISPLAY:
				return 12
		}
	}

	const getBookCard = (bookDetails: SimpleBookDetails) => {
		if (bookDetails.bookTitle === 'Add New Custom Book') {
			return <AddNewCustomBookCard {...bookDetails} userAccountType={userAccountType} />
		} else if (bookDetails.bookTitle.includes('quick-convert-text')) {
			return <QuickConvertTextCard {...bookDetails} userAccountType={userAccountType} />
		} else {
			return <BookCard {...bookDetails} userAccountType={userAccountType} />
		}
	}

	const addSpecialCustomBookCards = () => {
		let customizedAllActiveBookDetails = allActiveBookDetails

		// If quick convert text exists this moves it to the front of the list
		for (let [index, bookCard] of Object.entries(customizedAllActiveBookDetails)) {
			if (bookCard.bookTitle.includes('quick-convert-text')) {
				customizedAllActiveBookDetails = [
					customizedAllActiveBookDetails[+index], // quick convert text
					...customizedAllActiveBookDetails.slice(0, +index), // before orig quick convert text index
					...customizedAllActiveBookDetails.slice(+index + 1) // after orig quick convert text index
				]
				break
			}
		}

		// Include Add New Custom Book and Quick Convert Text Cards if custom display and one hasn't already been added (if statement prevents dev mode from adding new cards whenever it reloads)
		if (activeTab === BookDisplayTab.CUSTOM && customizedAllActiveBookDetails[0]?.bookTitle !== 'Add New Custom Book') {
			customizedAllActiveBookDetails = [{ ...addNewBookCard } as SimpleBookDetails, ...customizedAllActiveBookDetails]
		}

		return customizedAllActiveBookDetails
	}

	const generateBookGrid = (bookDetailsArr: SimpleBookDetails[]) => {
		return (
			<Grid container spacing={displayType === DisplayType.DESKTOP_DISPLAY ? 2 : 1}>
				{bookDetailsArr.map((bookDetails: SimpleBookDetails, index: number) => {
					return (
						<Grid item xs={getSectionSize()} key={`${activeTab}-${index}`}>
							<LazyLoad
								height={118}
								offset={isMobile ? 50 : 300}
								placeholder={<DisappearedLoading style={{}} color={text1} />} //color is black -- doesn't actually change on darkmode
								once>
								{getBookCard(bookDetails as SimpleBookDetails)}
							</LazyLoad>
						</Grid>
					)
				})}
			</Grid>
		)
	}

	const displayBooks = () => {
		renderLog('BOOK DISPLAY')
		if (activeTab === BookDisplayTab.CUSTOM) {
			const customizedAllActiveBookDetails = addSpecialCustomBookCards() // NOTE: customizes allActiveBookDetails array
			return generateBookGrid(customizedAllActiveBookDetails)
		} else {
			return generateBookGrid(allActiveBookDetails)
		}
	}

	// SECTION: Render UI
	return useMemo(() => {
		return activeTab === BookDisplayTab.CUSTOM && fetchingCustomBooks ? (
			<LoadingOverlay
				loadingOverlaySize={LoadingOverlaySize.BOUND}
				loadingBackgroundType={LoadingBackgroundType.NONE}
				loadingIconType={LoadingIconType.SEMIPOLAR_LOADING}
				loadingText={'Getting custom books...'}
			/>
		) : (
			displayBooks()
		)
	}, [
		Palette.getPalette(), // Need Palette.getPalette() with user userSettingsObj existing for darkmode to work
		allActiveBookDetails, // Needed to show anything
		activeTab, // Tab change not snappy without this
		// userAccountType,
		// fetchingCustomBooks,
		displayType // Required for screen size chage
	])
}

export default BooksDisplay
