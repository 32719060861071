import { useContext } from 'react'
import Link from 'next/link'
import { devLog, renderLog } from '../../../util/utilFunctions'
import { ButtonBase, Typography } from '@mui/material'
import { Palette } from '../../../util/palette/Palette'
import { UserAccountType } from '../../../types'
import { useStyles } from './BookSelectCardStyles'
import { BookSelectCardProps } from './BookCard'
import { AllInclusive } from '@mui/icons-material'
import { UtilContext } from '../../../contexts/UtilContext'

const QuickConvertTextCard = ({ pageCount, customBook, userAccountType }: BookSelectCardProps) => {
	const { classes, cx } = useStyles(Palette.getPalette())()

	const { userId } = useContext(UtilContext)

	return (
		<Link
			href={
				userAccountType === UserAccountType.PAID ? `/typing-console/quick-convert-text-${userId}/0/0` : `/users/account`
			}
			className={classes.innerLink}>
			{renderLog('QUICK CONVERT TEXT CARD')}
			<div
				className={cx(
					classes.root,
					customBook && userAccountType === UserAccountType.FREE && classes._unsubscribedBookCard
				)}>
				<div className={cx(classes.imageContainer, classes._iconImageContainer)}>
					<AllInclusive fontSize={'inherit'} className={classes.icon} />
				</div>
				{/* ButtonBase provides touch ripple effect */}
				<ButtonBase tabIndex={-1} className={classes.buttonBaseRipple}>
					<div
						className={cx(
							classes.cardContent,
							customBook && userAccountType === UserAccountType.FREE && classes._unsubscribedCardContent
						)}>
						<div className={classes.titleAuthorContainer}>
							<Typography className={classes.displayTitle} variant='h5' component='h3' noWrap={true}>
								Quick Convert Text
							</Typography>
						</div>
						<div className={cx(classes.lowerContainer, classes._pageCountContainer)}>
							<Typography className={classes.lowerText}>{pageCount ? `${pageCount} Pages` : ''}</Typography>
						</div>
					</div>
				</ButtonBase>
			</div>
		</Link>
	)
}

export default QuickConvertTextCard
