import Link from 'next/link'
import { devLog, renderLog } from '../../../util/utilFunctions'
import { Add } from '@mui/icons-material'
import { ButtonBase } from '@mui/material'
import { Palette } from '../../../util/palette/Palette'
import { UserAccountType } from '../../../types'
import { useStyles } from './BookSelectCardStyles'
import { BookSelectCardProps } from './BookCard'
import React from 'react'
import DisabledFeatureTooltip from '../../sharedComponents/tooltips/DisabledFeatureTooltip'
import { isImportAndFormatAccessable } from '../../../util/featureFlags'

const AddNewCustomBookCard = ({ customBook, userAccountType }: BookSelectCardProps) => {
	const { classes, cx } = useStyles(Palette.getPalette())()

	return (
		<DisabledFeatureTooltip placement='top' disabled={isImportAndFormatAccessable} forceFullChildWidth>
			{renderLog('ADD NEW CUSTOM BOOK CARD')}
			<Link
				href={userAccountType === UserAccountType.PAID ? `/bring-your-own-books/import` : '/premium'}
				className={classes.innerLink}>
				{/* Will redirect to marking page if user has no account */}

				<div
					className={cx(
						classes.root,
						classes._addCustomBookCard,
						customBook && userAccountType === UserAccountType.FREE && classes._unsubscribedBookCard
					)}>
					<ButtonBase tabIndex={-1} className={classes.buttonBaseRipple} aria-label={'Import Custom Book'}>
						<Add className={classes.icon} fontSize={'large'} />
					</ButtonBase>
				</div>
			</Link>
		</DisabledFeatureTooltip>
	)
}

export default AddNewCustomBookCard
