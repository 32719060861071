import { Typography } from '@mui/material'
import { Palette, PaletteTheme } from '../../util/palette/Palette'
import { makeStyles } from 'makeStyles'
import { renderLog } from '../../util/utilFunctions'
import { memo, useContext } from 'react'
import { UserSettingsContext } from '../../contexts/UserSettingsContext'

// SECTION: Styles
const useStyles = (palette: PaletteTheme) => {
	const { background4, text1 } = palette

	return makeStyles()({
		root: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-start',
			alignItems: 'flex-start',
			maxWidth: '37.5rem',
			width: '100%',
			backgroundColor: background4,
			color: text1,
			padding: '1rem 1.3rem',
			['@media (max-width:1279px)']: {
				maxWidth: 'initial'
			},
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				padding: '0.8rem 1rem'
			}
		},
		headerText: {
			fontSize: 18.5,
			fontWeight: 'bold',
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				fontSize: 19
			}
		},
		featuresContainer: {
			marginTop: '0.5rem',
			paddingLeft: '1rem',
			['@media (max-width:1279px)']: {
				paddingLeft: 0
			}
		},
		featuresList: {
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				paddingLeft: '1.1rem'
			}
		},
		featuresText: {
			margin: 0,
			marginTop: '0.1rem',
			minHeight: 0,
			height: '100%',
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				fontSize: 14
			}
		}
	})
}

const DescriptionContainer = memo(() => {
	// SECTION: Hooks
	const { userSettingsObj } = useContext(UserSettingsContext)

	const { classes } = useStyles(Palette.getPalette())()

	// SECTION: Render UI
	return (
		<div className={classes.root}>
			{renderLog('DESCRIPTION CONTAINER')}
			<Typography className={classes.headerText} component='h2'>
				Test your typing online by practicing on your favorite literature. Choose a book below to get started, or
				subscribe and import your own!
			</Typography>
			<div className={classes.featuresContainer}>
				<Typography className={classes.featuresList} component='ul'>
					<Typography className={classes.featuresText} component='li'>
						Improve your typing speed and accuracy in multiple languages
					</Typography>
					<Typography className={classes.featuresText} component='li'>
						Sign in, track your progress, and level up as you learn to type faster and better
					</Typography>
					<Typography className={classes.featuresText} component='li'>
						Tap into mindfulness — reduce stress and improve focus
					</Typography>
				</Typography>
			</div>
		</div>
	)
})

export default DescriptionContainer
