import { FormControl, InputLabel, NativeSelect, Select, MenuItem } from '@mui/material'
import { useEffect, useState } from 'react'
import { Palette, PaletteTheme } from '../../util/palette/Palette'
import { isMobile } from 'react-device-detect'
import { makeStyles } from 'makeStyles'
import { renderLog, _useEffectLog } from '../../util/utilFunctions'

// SECTION: Styles
const useStyles = (palette: PaletteTheme) => {
	const { text1, dropdownBackground1, dropdownSelected1, dropdownHover1 } = palette

	return makeStyles()({
		languageSelect: {
			width: '10rem',
			minHeight: '100%',
			marginRight: '0.1rem',
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				fontSize: 13,
				width: '7rem'
			},
			['@media (max-width:300px)']: {
				width: '5.5rem'
			},
			'& *': {
				color: `${text1} !important`, //!important prevents it turning black if reloading on another page (ie, account page)
				['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
					fontSize: 13
				}
			},
			'& .MuiInput-root': {
				minHeight: '2rem',
				border: 'none'
			},
			'& .MuiInput-underline:before': {
				borderBottomColor: text1 // Semi-transparent underline
			},
			'& .MuiInput-underline:hover:before': {
				borderBottomColor: `${text1} !important` // Solid underline on hover -- !important prevents it turning black if reloading on another page (ie, account page)
			},
			'& .MuiInput-underline:after': {
				borderBottomColor: text1 // Solid underline on focus
			}
		},
		languageSelectMenu: {
			color: text1,
			backgroundColor: dropdownBackground1,
			'& ul': {
				backgroundColor: dropdownBackground1
			},
			'& li': {
				backgroundColor: dropdownBackground1
			},
			'& li:focus': {
				backgroundColor: dropdownHover1
			},
			'& li:hover': {
				backgroundColor: dropdownHover1
			},
			'& .Mui-selected': {
				backgroundColor: dropdownSelected1
			},
			'& .Mui-selected:focus': {
				backgroundColor: dropdownSelected1
			},
			'& .Mui-selected:hover': {
				backgroundColor: dropdownSelected1
			}
		}
	})
}

// SECTION: Props
interface LanguageSelectProps {
	showLanguageSelect: boolean
	languageFilterCallback: Function
}

const LanguageSelect = ({ showLanguageSelect, languageFilterCallback }: LanguageSelectProps) => {
	// SECTION: Hooks
	const [languageFilter, setLanguageFilter] = useState('English' as string | unknown) // unknown to stop language select from complaining

	// USE EFFECT: Signals BookDisplay to change based on selected language
	useEffect(() => {
		_useEffectLog('LANGUAGE SELECT useEffect 1')
		languageFilterCallback(languageFilter)
	}, [languageFilter])

	const { classes } = useStyles(Palette.getPalette())()

	// SECTION: UI Getters
	const getDesktopLanguageSelect = () => {
		renderLog('LANGUAGE SELECT')

		return (
			<FormControl variant='standard' className={classes.languageSelect} aria-label='language select'>
				<InputLabel id='language-select'>Language</InputLabel>
				<Select
					labelId='language-select'
					MenuProps={{ classes: { paper: classes.languageSelectMenu } }} // Required to style dropdown menu
					value={languageFilter}
					onChange={(event) => setLanguageFilter(event.target.value)}>
					<MenuItem value={'English'}>English</MenuItem>
					<MenuItem value={'Dutch'}>Dutch</MenuItem>
					<MenuItem value={'Finnish'}>Finnish</MenuItem>
					<MenuItem value={'French'}>French</MenuItem>
					<MenuItem value={'German'}>German</MenuItem>
					<MenuItem value={'Italian'}>Italian</MenuItem>
					<MenuItem value={'Portuguese'}>Portuguese</MenuItem>
					<MenuItem value={'Russian'}>Russian</MenuItem>
					<MenuItem value={'Spanish'}>Spanish</MenuItem>
				</Select>
			</FormControl>
		)
	}

	const getMobileLanguageSelect = () => {
		renderLog('LANGUAGE SELECT')

		return (
			<FormControl className={classes.languageSelect} aria-label='language select'>
				<InputLabel htmlFor='language-select'>Language</InputLabel>
				<NativeSelect
					id='language-select'
					value={languageFilter}
					onChange={(event) => setLanguageFilter(event.target.value)}>
					<option value={'English'}>English</option>
					<option value={'Dutch'}>Dutch</option>
					<option value={'Finnish'}>Finnish</option>
					<option value={'French'}>French</option>
					<option value={'German'}>German</option>
					<option value={'Italian'}>Italian</option>
					<option value={'Portuguese'}>Portuguese</option>
					<option value={'Russian'}>Russian</option>
					<option value={'Spanish'}>Spanish</option>
				</NativeSelect>
			</FormControl>
		)
	}

	// SECTION: Render UI
	return showLanguageSelect ? (isMobile ? getMobileLanguageSelect() : getDesktopLanguageSelect()) : null
}

export default LanguageSelect
