import Link from 'next/link'
import Image from 'next/image'
import { devLog, getBaseUrl, renderLog } from '../../../util/utilFunctions'
import { ButtonBase, Typography } from '@mui/material'
import { Palette } from '../../../util/palette/Palette'
import { UserAccountType } from '../../../types'
import { useStyles } from './BookSelectCardStyles'
import { SimpleBookDetails } from '../BookSelectPage'
import CustomBookCover from '../../sharedComponents/CustomBookCover'

export interface BookSelectCardProps extends SimpleBookDetails {
	userAccountType: UserAccountType
}

// QUIRK: Memoization handled in BookDisplay
const BookCard = ({
	bookTitle,
	displayTitle,
	author,
	pageCount,
	coverName,
	customCoverUrl,
	customBook,
	userAccountType
}: BookSelectCardProps) => {
	const { classes, cx } = useStyles(Palette.getPalette())()

	const getLinkUrl = () => {
		if (customBook && userAccountType === UserAccountType.FREE) {
			// If custom book but user's subscription expired, link them to account page where they can resubscribe
			return `/users/account`
		} else {
			// If classic book OR Custom Book with Paid user account, nav to chapter page
			return `/chapters/${bookTitle.split(' ').join('_')}`
		}
	}

	return (
		<Link href={getLinkUrl()} className={classes.innerLink}>
			{renderLog('BOOK CARD')}
			<div
				className={cx(
					classes.root,
					customBook && userAccountType === UserAccountType.FREE && classes._unsubscribedBookCard
				)}>
				<div className={classes.imageContainer}>
					{!coverName || coverName === 'custom-cover' ? (
						<CustomBookCover
							imageWidth={80}
							imageHeight={118}
							customCoverUrl={customCoverUrl}
							showBackgroundOnEmptyImage={true}
						/>
					) : (
						<Image
							src={`${getBaseUrl()}/images/${
								customBook ? 'thumbnailCustomBookDefaultCovers' : 'thumbnailBookCovers'
							}/${coverName}.jpg`}
							alt={customBook ? '' : `${displayTitle} Cover`}
							className={classes.image}
							width={80}
							height={118}
						/>
					)}
				</div>
				{/* ButtonBase provides touch ripple effect */}
				<ButtonBase tabIndex={-1} className={classes.buttonBaseRipple}>
					<div
						className={cx(
							classes.cardContent,
							customBook && userAccountType === UserAccountType.FREE && classes._unsubscribedCardContent
						)}>
						<div className={classes.titleAuthorContainer}>
							<Typography className={classes.displayTitle} variant='h5' component='h3' noWrap={true}>
								{displayTitle || '\u00A0'}
								{'\u00A0'}
							</Typography>
							<Typography className={classes.subText} component='h4' noWrap={true}>
								{author}
								{'\u00A0'}
							</Typography>
						</div>
						<div className={cx(classes.lowerContainer, classes._pageCountContainer)}>
							<Typography className={classes.lowerText} component='span'>
								{pageCount ? `${pageCount} Pages` : ''}
							</Typography>
						</div>
					</div>
				</ButtonBase>
			</div>
		</Link>
	)
}

export default BookCard
