import { useSession } from 'next-auth/react'
import Head from 'next/head'
import { useContext, useState, useEffect, useLayoutEffect } from 'react'
import { UserLayoutStateContext } from '../../contexts/UserLayoutStateContext'
import { UserNotesContext } from '../../contexts/UserNotesContext'
import { UserSettingsContext } from '../../contexts/UserSettingsContext'
import { UtilContext } from '../../contexts/UtilContext'
import { getAllCustomBookDetailsFromIdb } from '../../functions/idb/customBookDetailsIdb'
import { UserAccountType } from '../../types'
import { handleErrorOnFrontend } from '../../util/errorHandlers'
import { Palette, PaletteTheme } from '../../util/palette/Palette'
import { _useEffectLog, devLog, renderLog, devErr, getBaseUrl } from '../../util/utilFunctions'
import FooterBar from '../sharedComponents/FooterBar'
import BooksDisplay from './BooksDisplay'
import BooksDisplayTabs, { BookDisplayTab } from './BooksDisplayTabs'
import DescriptionContainer from './DescriptionContainer'
import LanguageSelect from './LanguageSelect'
import NewsContainer from './NewsContainer'
import { makeStyles } from 'makeStyles'
import Image from 'next/image'

// SECTION: Styles
const useStyles = (palette: PaletteTheme) => {
	const { mainBackgroundColor, text1 } = palette

	return makeStyles()({
		pageBackground: {
			backgroundColor: mainBackgroundColor
		},
		root: {
			display: 'flex',
			flexDirection: 'column',
			minHeight: '170vh', //NOTE: iOS and Safari wont update what 100vh is (if more elements appear, won't recalculate), so you need make it the min hight
			background: 'none', //NOTE: Also, vh is above 100 so that if user changes language, they don't get the jarring thing where the screen jams back up suddenly
			'& .lazyload-wrapper': {
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center'
			}
		},
		contentContainer: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			width: '100%'
		},
		innerContentContainer: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			maxWidth: '78rem',
			padding: '2rem',
			['@media (max-width:300px)']: {
				padding: '0.5rem'
			}
		},
		maintenanceWarning: {
			color: text1,
			border: `1px dashed #FF9900`,
			padding: '4px 8px',
			marginBottom: 20
		},
		topRow: {
			display: 'flex',
			justifyContent: 'space-between',
			width: '100%',
			paddingRight: '10px',
			marginBottom: '2rem',
			['@media (max-width:1279px)']: {
				paddingRight: 0
			},
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				marginBottom: '1rem'
			}
		},
		_hidden: {
			display: 'none'
		},
		demoContainer: {
			display: 'flex',
			justifyContent: 'flex-end'
		},

		tabsAndSelectsContainer: {
			display: 'flex',
			justifyContent: 'space-between',
			minHeight: '3rem',
			marginBottom: '0.5rem'
		},
		tabsContainer: {
			display: 'flex',
			width: '100%',
			paddingTop: '0.2rem'
		},
		languageSelectContainer: {
			display: 'flex',
			justifyContent: 'flex-end'
		},
		bookDisplayContainer: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			minHeight: '30rem',
			padding: 0
		}
	})
}

// SECTION: Props
export interface SimpleBookDetails {
	bookTitle: string
	displayTitle: string
	author: string
	language: string
	pageCount: number
	coverName: string
	customCoverFile?: File
	customCoverUrl?: string
	customBook?: boolean
}

export interface BookSelectPageProps {
	allClassicBookDetails: SimpleBookDetails[]
}

const BookSelectPage = ({ allClassicBookDetails }: BookSelectPageProps) => {
	// SECTION: Hooks
	const { userSettingsObj, getDarkModeEnabled } = useContext(UserSettingsContext)
	const {
		windowWidth,
		userId,
		userAccountType,
		getIsFirstVisit,
		setIsFirstVisitFalse,
		isMobileDisplay,
		getDisplayType,
		isDesktopDisplay
	} = useContext(UtilContext)
	const { setError } = useContext(UserNotesContext)
	const { userLayoutStateObj, getActiveBookDisplayTab, setActiveBookDisplayTab } = useContext(UserLayoutStateContext)

	const [allCustomBookDetails, setAllCustomBookDetails] = useState([] as SimpleBookDetails[])
	const [allSortedActiveBookDetails, setAllSortedActiveBookDetails] = useState([] as SimpleBookDetails[])
	const [languageFilter, setLanguageFilter] = useState('English' as string | unknown) // unknown to stop language select from complaining

	const [activeTab, setActiveTab] = useState(BookDisplayTab.CLASSICS) // if active tab not clussic, set by useLayoutEffect
	const [fetchingCustomBooks, setFetchingCustomBooks] = useState(true)
	const [fetchingInitialActiveBookDisplayTab, setFetchingInitialActiveBookDisplayTab] = useState(true)

	const { data: session, status } = useSession()

	const { classes, cx } = useStyles(Palette.getPalette())()

	// USE EFFECT: Get CustomBooks and add to all book details if any + if some exist but not logged in display error
	useEffect(() => {
		_useEffectLog('BOOK PAGE useEffect 0.5')
		const loadAllSimpleCustomBookDetails = async () => {
			if (userId) {
				try {
					let newAllSimpleBookDetails = await getAllCustomBookDetailsFromIdb(userId)

					// Extract Custom book cover urls from idb image files
					newAllSimpleBookDetails = newAllSimpleBookDetails.map((bookDetails) => {
						if (bookDetails.customCoverFile) {
							try {
								bookDetails.customCoverUrl = URL.createObjectURL(bookDetails.customCoverFile)
							} catch (err) {
								devErr(err)
							}
						}

						return bookDetails
					})

					setAllCustomBookDetails(newAllSimpleBookDetails)
				} catch (err) {
					handleErrorOnFrontend(err, "Couldn't load custom texts.", setError)
				} finally {
					setFetchingCustomBooks(false)
				}
			} else if (session === null) {
				setFetchingCustomBooks(false) // user not logged in
			}
		}

		loadAllSimpleCustomBookDetails()
	}, [session, userId])

	// USE EFFECT: If in custom tab and custom books exist for user, but subsciption has run out, show error
	useEffect(() => {
		_useEffectLog('BOOK PAGE useEffect 1')

		if (
			activeTab === BookDisplayTab.CUSTOM &&
			userAccountType === UserAccountType.FREE &&
			allCustomBookDetails.length > 0
		) {
			setError(
				'Subscription not found: Please renew your subscription from the Account page to regain access to these custom books.'
			)
		}
	}, [activeTab, allCustomBookDetails])

	// USE EFFECT: Removes above error if tab switched to Classic
	useEffect(() => {
		_useEffectLog('BOOK PAGE useEffect 1.1')

		if (activeTab === BookDisplayTab.CLASSICS) {
			setError('')
		}
	}, [activeTab])

	// USE EFFECT: Get initial tab once Layout State context is loaded
	// NOTE: If activeBookDisplayTab value is corrupted, tabs are still shown and will set new value when clicked on
	// NOTE: Code in _app.tsx ensures that server uses useEffect version instead (gets rid of warning)
	useLayoutEffect(() => {
		_useEffectLog('BOOK PAGE useEffect 1.5')
		if (fetchingInitialActiveBookDisplayTab && Object.keys(userLayoutStateObj).length > 0) {
			setActiveTab(getActiveBookDisplayTab() ? getActiveBookDisplayTab() : BookDisplayTab.CLASSICS) // Ensure a tab is active or it wont show BookSelect
			setFetchingInitialActiveBookDisplayTab(false)
		}
	}, [userLayoutStateObj])

	// USE EFFECT: Save active tab to Ls on tab change
	useEffect(() => {
		_useEffectLog('BOOK PAGE useEffect 2')

		// Prevent saving nulls + prevent non-logged in users from saving CUSTOM tab
		if (!activeTab || (!session && activeTab === BookDisplayTab.CUSTOM)) return

		setActiveBookDisplayTab(activeTab)
	}, [activeTab])

	// USE EFFECT: Updates BookDisplay on tab or language changes, or custom books done loading
	// NOTE: useLayoutEffect runs before printing UI to the screen -- prevents UI flicker when going between tabs
	useLayoutEffect(() => {
		_useEffectLog('BOOK PAGE useEffect 3')

		const allActiveBookDetails = getActiveBookDetails()

		if (!allActiveBookDetails || (activeTab === BookDisplayTab.CUSTOM && fetchingCustomBooks)) return // if tab hasn't been fetched from context yet, this may be undefined

		// If BookDisplayTab is Classic => Filter by language
		const filteredAllBookDetails =
			activeTab === BookDisplayTab.CLASSICS ? filterBooksByLanguage(allActiveBookDetails) : allActiveBookDetails

		// Sort books by displayTitle
		const sortedFilteredAllBookDetails = sortBooks(filteredAllBookDetails, 'displayTitle')
		setAllSortedActiveBookDetails(sortedFilteredAllBookDetails)
	}, [activeTab, languageFilter, fetchingInitialActiveBookDisplayTab, fetchingCustomBooks])

	// HELPERS
	const filterBooksByLanguage = (bookDetails: SimpleBookDetails[]) => {
		const normalizedBookDetails = bookDetails.map((book) => {
			const curBook = book
			if (!book.displayTitle) {
				curBook.displayTitle = curBook.bookTitle
			}

			if (!book.language) {
				curBook.language = 'English'
			}

			return curBook
		})
		return normalizedBookDetails?.filter((book) => {
			return book.language === languageFilter
		})
	}

	const sortBooks = (bookDetails: SimpleBookDetails[], sortProperty: 'bookTitle' | 'displayTitle') => {
		return bookDetails.sort((a, b) => {
			if (
				a[sortProperty]?.replace('The ', '').replace('A ', '') < b[sortProperty]?.replace('The ', '').replace('A ', '')
			) {
				return -1
			} else if (
				a[sortProperty]?.replace('The ', '').replace('A ', '') > b[sortProperty]?.replace('The ', '').replace('A ', '')
			) {
				return 1
			} else {
				return 0
			}
		})
	}

	// USE EFFECT: Acknowledge first visit (currently only used SEO functionality -- if user left custom tab open they can come back to it, but if google crawler it will always default to the CLASSICS tab without waiting to check session)
	useEffect(() => {
		_useEffectLog('BOOK PAGE useEffect 4')

		// ensure theres an active tab set before changing this
		if (getIsFirstVisit() && activeTab) {
			setIsFirstVisitFalse()
		}
	}, [])

	// SECTION: Functionality
	const getActiveBookDetails = () => {
		const activeBookDetailsLookup: { [activeTab: string]: SimpleBookDetails[] } = {
			CLASSICS: allClassicBookDetails,
			CUSTOM: allCustomBookDetails
		}

		if (!activeTab) return

		return activeBookDetailsLookup[activeTab]
	}

	// SECTION: Render UI
	return (
		<>
			<Head>
				<title>Practice typing by retyping ENTIRE novels — TypeLit.io</title>
				<meta
					name='description'
					content='Typing Practice | Test your typing while reading great books like Alice in Wonderland, 1984, Dracula, and The Art of War — or import your own material!'
				/>
				{/* // NOTE: Below is good except it *might* be too long and apparantly google doesn't check meta descriptions for keywords anyway  */}
				{/* <meta
					name='description'
					content='Free Typing Practice | Test typing speed, improve focus, and learn about classic literature while reading great books! Enhance your school curriculum by engaging students in fun keyboard training designed to teach English skills like writing, vocabulary, grammar, and more!'
				/> */}
				<meta name='robots' content='index, follow' />
				<link rel='canonical' href='https://www.typelit.io/' />

				{/* // NOTE: Moved og meta tags to _document.tsx */}
			</Head>

			<div className={classes.pageBackground}>
				<div className={classes.root}>
					{renderLog('BOOK SELECT PAGE')}
					<main className={classes.contentContainer}>
						{/* h1 lives in the header when on homepage */}
						<div className={classes.innerContentContainer}>
							<>
								{/* _hidden prevents pop-in (even if window exists, there may not be width yet -- works with SSG) */}
								<div className={cx(classes.topRow, !windowWidth && classes._hidden)}>
									<DescriptionContainer />
									{isDesktopDisplay() && (
										<div className={classes.demoContainer}>
											<Image
												src={`${getBaseUrl()}/images/${
													getDarkModeEnabled() || getDarkModeEnabled() === undefined
														? 'demo-img-dark.jpg'
														: 'demo-img-light.jpg'
												}`}
												alt={`Typing Demo Img`}
												width={544}
												height={220}
												priority={getDarkModeEnabled()} // Only preload if dark mode image
											/>
										</div>
									)}
								</div>

								{/* // NOTE: Maintenance Warning -- Comment out when not in use */}
								{/* windowWidth prevents this from from appearing before everything else */}
								{/* {windowWidth > 0 && (
									<div className={classes.maintenanceWarning} data-nosnippet>
										* Maintenance planned for the coming weeks. Service may be down sporadically during this time. *
									</div>
								)} */}

								{/* Trying to get this to render statically procuces MUI controlled vs uncontrolled error */}
								{!isMobileDisplay() && <NewsContainer />}

								{/* Trying to get these to render statically (ie, without windowWidth > 0) throws server mismatch errors) */}
								{windowWidth > 0 && (
									<>
										<div className={classes.tabsAndSelectsContainer}>
											<div className={classes.tabsContainer}>
												<BooksDisplayTabs activeTab={activeTab} setActiveTabCb={setActiveTab} />
											</div>
											<div className={classes.languageSelectContainer}>
												<LanguageSelect
													showLanguageSelect={activeTab === BookDisplayTab.CLASSICS}
													languageFilterCallback={setLanguageFilter}
												/>
											</div>
										</div>
										<div className={classes.bookDisplayContainer}>
											<BooksDisplay
												allActiveBookDetails={allSortedActiveBookDetails}
												activeTab={activeTab}
												fetchingCustomBooks={fetchingCustomBooks}
												userAccountType={userAccountType}
												displayType={getDisplayType()}
											/>
										</div>
									</>
								)}
							</>
						</div>
					</main>
					<FooterBar />
				</div>
			</div>
		</>
	)
}

export default BookSelectPage
