import { makeStyles } from 'makeStyles'
import { PaletteTheme } from '../../../util/palette/Palette'

export const useStyles = (palette: PaletteTheme) => {
	const {
		background1,
		background4,
		border1,
		text1,
		background8,
		border2,
		cardRipple,
		border6,
		background9,
		cardHoverTransition
	} = palette

	return makeStyles()({
		root: {
			display: 'flex',
			height: '7.5rem',
			cursor: 'pointer',
			overflow: 'hidden',
			border: `solid 1px ${border1}`,
			backgroundColor: background1,
			transition: cardHoverTransition,
			color: text1, // uneeded, but keep because non-found convert text does wierd stuff in dev mode otherwise
			'&:hover': {
				backgroundColor: background4
			},
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				height: '5rem',
				margin: 0
			}
		},
		_unsubscribedBookCard: {
			backgroundColor: 'revert',
			borderColor: border6,
			'&:hover': {
				backgroundColor: 'revert'
			}
		},
		_addCustomBookCard: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			width: '100%',
			backgroundColor: background8, // same bg as page background
			borderColor: border2
		},
		// Needed in order to allow open new page with right click
		innerLink: {
			width: '100%',
			textDecoration: 'none'
		},
		imageContainer: {
			width: '100%',
			maxWidth: 80, // width = 5rem x 16px = 80px // height = 118px
			maxHeight: 118,
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				maxWidth: 53.12
			}
		},
		image: {
			width: '100%',
			height: '100%'
		},
		// Used by Quick Convert Text Card
		_iconImageContainer: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			fontSize: 35, // large icon
			backgroundColor: background9,
			boxShadow: `0 0 20px 0 inset ${background1}`,
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				fontSize: 24 // Medium icon
			}
		},
		icon: {
			color: text1
		},
		buttonBaseRipple: {
			width: '100%',
			height: '100%',
			overflow: 'hidden',
			color: cardRipple
		},
		cardContent: {
			display: 'flex',
			flexDirection: 'column',
			height: '7.5rem', // Need to specify exact height for safari
			width: '100%',
			color: text1,
			padding: '0.3rem',
			['@media (max-width:1279px)']: {
				paddingLeft: '0.5rem',
				paddingRight: '0.5rem'
			},
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				height: '5rem'
			}
		},
		_unsubscribedCardContent: {
			color: 'grey'
		},
		titleAuthorContainer: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-start',
			maxWidth: '100%',
			textAlign: 'start'
		},
		displayTitle: {
			fontWeight: 'bold',
			paddingLeft: '0.3rem',
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				fontSize: '1rem',
				padding: 0
			}
		},
		subText: {
			fontSize: 14,
			fontWeight: 'normal',
			lineHeight: 'initial',
			paddingLeft: '0.3rem',
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				padding: 0
			}
		},
		lowerContainer: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-end',
			textAlign: 'start',
			flexGrow: 1,
			paddingLeft: '0.3rem',
			paddingRight: '0.3rem'
		},
		_pageCountContainer: {
			textAlign: 'end'
		},
		lowerText: {
			['@media (max-width:550px) and (min-height: 415px), (min-width:415px) and (max-height: 550px)']: {
				fontSize: '0.8rem'
			}
		}
	})
}
