import { dbLog, devErr, devLog, isNodeInDevelopmentMode } from '../util/utilFunctions'
import { getClassicBookCoverName } from '../functions/sharedFunctions/bookUtil'
import BookDetailsModel from '../models/bookDetailsModel'
import { connectMongooseDb } from '../util/database/mongooseDatabase'
import BookSelectPage, { BookSelectPageProps, SimpleBookDetails } from '../components/BookSelectPage/BookSelectPage'

// SECTION: Render Page
const BookSelectPageWrapper = (props: BookSelectPageProps) => {
	return <BookSelectPage {...props} />
}

// SECTION: getStaticProps
// Only runs during build time
export const getStaticProps = async () => {
	let allClassicBookDetails
	try {
		// NOTE: Required when creating build or using @next/bundle-analyzer -- static pages are built seperate from server.js running.
		if (!isNodeInDevelopmentMode()) {
			await connectMongooseDb()
		}

		// Get all basic chapter details and supply them as props
		dbLog('BookSelectPage Static: BookDetails find')
		allClassicBookDetails = (await BookDetailsModel.find(
			{},
			'bookTitle displayTitle author language pageCount -_id'
		).lean()) as SimpleBookDetails[]

		// Triggers 404 page if there is no BookDetails -- errors go to the server
		if (!allClassicBookDetails || allClassicBookDetails.length === 0)
			throw Error('Result of BookDetails query null or empty.')

		allClassicBookDetails = allClassicBookDetails.map((bookDetails) => {
			bookDetails.coverName = getClassicBookCoverName(bookDetails.bookTitle)
			return bookDetails
		})

		return {
			props: {
				allClassicBookDetails
			}
		}
	} catch (err) {
		// Don't bother sending error sentry -- it will be obvious if there's an error during build time
		console.error(err)
		return { notFound: true }
	}
}

export default BookSelectPageWrapper
